import React, { useState } from 'react';
import classNames from 'classnames';

import Layout from '../components/Layout';

import DecorationLines from '../assets/images/decorations/lines-main.svg';

import '../assets/styles/pages/legal-page.scss';

const tabsData = [
  {
    tab: 'Terms of Service',
    body: (
      <>
        <h2>Terms of Service</h2>
        <p>
          These Terms of Service govern your use of the website located at{' '}
          <a href="https://jetic.io" target="_blank" rel="noopener noreferrer">
            https://jetic.io
          </a>
          , and any other sites, platforms and/or related services (collectively, the “website”)
          provided by Datagrate Inc. (referred as Datagrate).
        </p>
        <p>
          By accessing{' '}
          <a href="https://jetic.io" target="_blank" rel="noopener noreferrer">
            https://jetic.io
          </a>
          , you agree to abide by these Terms of Service and to comply with all applicable laws and
          regulations. If you do not agree with these Terms of Service, you are prohibited from
          using or accessing this website or using any other services provided by Datagrate.
        </p>
        <p>
          We, Datagrate, reserve the right to review and amend any of these Terms of Service at our
          sole discretion. Upon doing so, we will update this page. Any changes to these Terms of
          Service will take effect immediately from the date of publication.
        </p>
        <p>These Terms of Service were last updated on 5 August 2021.</p>
        <h2>Limitations of Use</h2>
        <p>
          By using this website, you warrant on behalf of yourself, your users, and other parties
          you represent that you will not:
        </p>
        <ol>
          <li>
            modify, copy, prepare derivative works of, decompile, or reverse engineer any materials
            and software contained on this website;
          </li>
          <li>
            remove any copyright or other proprietary notations from any materials and software on
            this website;
          </li>
          <li>
            transfer the materials to another person or “mirror” the materials on any other server;
          </li>
          <li>
            knowingly or negligently use this website or any of its associated services in a way
            that abuses or disrupts our networks or any other service Datagrate provides;
          </li>
          <li>
            use this website or its associated services to transmit or publish any harassing,
            indecent, obscene, fraudulent, or unlawful material;
          </li>
          <li>
            use this website or its associated services in violation of any applicable laws or
            regulations;
          </li>
          <li>use this website in conjunction with sending unauthorized advertising or spam;</li>
          <li>harvest, collect, or gather user data without the user’s consent; or</li>
          <li>
            use this website or its associated services in such a way that may infringe the privacy,
            intellectual property rights, or other rights of third parties.
          </li>
        </ol>
        <h2>Accounts and Registration</h2>
        <p>
          You acknowledge and agree that, when you elect to access or use certain services on the
          website that require registration, in consideration for your use of such services, you
          will: (a) provide complete and accurate information about yourself in the required fields
          in any registration forms and (b) promptly update such information to keep it accurate,
          current, and complete. If you provide any information that is untrue, inaccurate, not
          current, or incomplete, or if we have reasonable grounds to suspect that the information
          not true, accurate, current, or complete, we may suspend or terminate your account and/or
          refuse any current or future use of the website, including any services.
        </p>
        <h2>Storage and Availability</h2>
        <p>
          We are not a content-archiving service. We do not promise to store or make available on
          our website any User Content that you submit, or any other Content, for any length of
          time. You are solely responsible for keeping back-ups of everything you post on our
          website. You acknowledge that temporary interruptions in the availability of the website
          may occur from time to time as normal events. Also, we may decide to cease making
          available the website or any portion of the website at any time and for any reason. Under
          no circumstances will Datagrate be held liable for any damages due to such interruptions
          or lack of availability.
        </p>
        <h2>Intellectual Property</h2>
        <p>
          The intellectual property in the materials contained in this website are owned by or
          licensed to Datagrate and are protected by applicable copyright and trademark law. We
          grant our users permission to download one copy of the materials for personal,
          non-commercial transitory use.
        </p>
        <p>
          This permission shall automatically terminate if you violate any of these restrictions or
          the Terms of Service, and may be terminated by Datagrate at any time.
        </p>
        <h2>Liability</h2>
        <p>
          Our website and the materials on our website are provided on an 'as is' basis. To the
          extent permitted by law, Datagrate makes no warranties, expressed or implied, and hereby
          disclaims and negates all other warranties including, without limitation, implied
          warranties or conditions of merchantability, fitness for a particular purpose, or
          non-infringement of intellectual property, or other violation of rights.
        </p>
        <p>
          In no event shall Datagrate or its suppliers be liable for any consequential loss suffered
          or incurred by you or any third party arising from the use or inability to use this
          website or the materials on this website, even if Datagrate or an authorized
          representative has been notified, orally or in writing, of the possibility of such damage.
        </p>
        <p>
          In the context of this agreement, “consequential loss” includes any consequential loss,
          indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue, loss
          of business, loss of goodwill, loss of opportunity, loss of savings, loss of reputation,
          loss of use and/or loss or corruption of data, whether under statute, contract, equity,
          tort (including negligence), indemnity, or otherwise.
        </p>
        <p>
          Because some jurisdictions do not allow limitations on implied warranties, or limitations
          of liability for consequential or incidental damages, these limitations may not apply to
          you.
        </p>
        <h2>Disclaimers</h2>
        <p>
          While we endeavor to provide the most accurate, up-to-date information available, the
          services, directories and information contained on the website may, at times, contain
          technical inaccuracies or typographical errors, and may be changed or updated without
          notice. We cannot guarantee that the services, content or other information provided
          through the website will be accurate or up-to-date. We reserve the right without prior
          notice to discontinue or change the specifications on products and services offered on the
          website without obligations. From time to time, we may make suggestions or recommendations
          of certain products or services. However, we make no guarantee as to satisfaction for our
          suggestions or recommendations or that the suggested or recommended products or services
          will meet your expectations.
        </p>
        <h2>Privacy Policy</h2>
        <p>
          We consider the privacy of our users to be paramount, and we have developed a privacy
          policy to protect and inform our users. Our current <u>Privacy Policy</u> is incorporated
          herein by reference and made part of these Terms.
        </p>
        <h2>Accuracy of Materials</h2>
        <p>
          The materials appearing on our website are not comprehensive and are for general
          information purposes only. Datagrate does not warrant or make any representations
          concerning the accuracy, likely results, or reliability of the use of the materials on
          this website, or otherwise relating to such materials or on any resources linked to this
          website.
        </p>
        <h2>Links</h2>
        <p>
          The website may contain links to other websites on the Internet that are owned and
          operated by third party vendors and other third parties. Datagrate is providing these
          links solely as a convenience. The appearance of a link does not imply our endorsement of
          the link/website, nor are we responsible for the content of any linked website. Use of any
          such linked website is at your own risk and we strongly advise you make your own
          investigations with respect to the suitability of those websites.
        </p>
        <h2>Right to Terminate</h2>
        <p>
          We may suspend or terminate your right to use our website and terminate these Terms of
          Service immediately without written notice to you for any violation of these Terms of
          Service. We may terminate or suspend the website or any part of the website, terminate or
          suspend your use of the website, block any IP address, or remove any of your User Content
          at any time without any liability to you. Further, we may terminate or suspend your
          permission to use the website immediately and without notice upon your failure to pay any
          fees when due, upon the request of law enforcement or government agencies, for extended
          periods of inactivity, for unexpected technical issues or problems or for engagement by
          you in fraudulent or illegal activities. If we terminate your use of the website for any
          of these reasons or otherwise for cause, we will not refund any fees you may have paid,
          whether for access to the website. Upon any termination we may delete your account,
          passwords and User Content, and we may bar you from further use of the website. You
          understand that we may also continue to make your User Content available on the website
          even if your use of the website is terminated or suspended. You agree that we will have no
          liability to you or any third party for termination of your account or access to the
          website.
        </p>
        <h2>Severance</h2>
        <p>
          Any term of these Terms of Service which is wholly or partially void or unenforceable is
          severed to the extent that it is void or unenforceable. The validity of the remainder of
          these Terms of Service is not affected.
        </p>
        <h2>Disputes and Governing Law</h2>
        <p>
          You agree that any action at law or in equity arising out of or relating to these Terms
          shall be filed only in the state or federal courts of Illinois, USA, and that you hereby
          consent and submit to the exclusive jurisdiction and venue of such courts. No action
          arising under or relating to these Terms may be brought by either party more than one year
          after the cause of action has occurred.
        </p>
        <h2>Special Admonitions for International Use</h2>
        <p>
          Recognizing the global nature of the Internet, you agree to comply with all local rules
          regarding online conduct and acceptable content. Specifically, you agree to comply with
          all applicable laws regarding the transmission of technical data and software exported
          from the United States and the export and import laws of the country in which you reside.
        </p>
        <h2>Contact Us</h2>
        <p>
          For any questions or concerns regarding these Terms and Conditions, please contact us by
          sending an email to <a href="mailto:support@jetic.io">support@jetic.io</a>, by visiting
          this page on our website{' '}
          <a href="https://jetic.io" target="_blank" rel="noopener noreferrer">
            https://jetic.io
          </a>{' '}
          or by writing to us at:
        </p>
        <br />
        <div>
          <span>Datagrate Inc. </span>
        </div>
        <div>
          <span>1658 N. Milwaukee Ave. Suite 100-2145</span>
        </div>
        <div>
          <span>Chicago, IL 60647</span>
        </div>
      </>
    ),
  },
  {
    tab: 'Cookie Policy',
    body: (
      <>
        <h2>Cookie Policy</h2>
        <p>
          We use cookies to help improve your experience of our website at{' '}
          <a href="https://jetic.io" target="_blank" rel="noopener noreferrer">
            https://jetic.io
          </a>
          , and any other sites, platforms and/or related services (collectively, the “website”).
          This cookie policy is part of Datagrate Inc. (referred as Datagrate) <u>Privacy Policy</u>
          . It covers the use of cookies between your device and our website.
        </p>
        <p>
          We also provide basic information on third-party services we may use, who may also use
          cookies as part of their service. This policy does not cover their cookies.
        </p>
        <p>
          If you don’t wish to accept cookies from us, you should instruct your browser to refuse
          cookies from{' '}
          <a href="https://jetic.io" target="_blank" rel="noopener noreferrer">
            https://jetic.io
          </a>
          . In such a case, we may be unable to provide you with some of your desired content and
          services.
        </p>
        <h2>What is a cookie?</h2>
        <p>
          A cookie is a small piece of data that a website stores on your device when you visit. It
          typically contains information about the website itself, a unique identifier that allows
          the site to recognize your web browser when you return, additional data that serves the
          cookie’s purpose, and the lifespan of the cookie itself.
        </p>
        <p>
          Cookies are used to enable certain features (e.g. logging in), track site usage (e.g.
          analytics), store your user settings (e.g. time zone, notification preferences), and to
          personalize your content (e.g. advertising, language).
        </p>
        <p>
          Cookies set by the website you are visiting are usually referred to as first-party
          cookies. They typically only track your activity on that particular site.
        </p>
        <p>
          Cookies set by other sites and companies (i.e. third parties) are called third-party
          cookies. They can be used to track you on other websites that use the same third-party
          service.
        </p>
        <h2>Types of cookies and how we use them</h2>
        <h2>Essential cookies</h2>
        <p>
          Essential cookies are crucial to your experience of a website, enabling core features like
          user logins, account management, shopping carts, and payment processing.
        </p>
        <p>We use essential cookies to enable certain functions on our website.</p>
        <h2>Performance cookies</h2>
        <p>
          Performance cookies track how you use a website during your visit. Typically, this
          information is anonymous and aggregated, with information tracked across all site users.
          They help companies understand visitor usage patterns, identify and diagnose problems or
          errors their users may encounter, and make better strategic decisions in improving their
          audience’s overall website experience. These cookies may be set by the website you’re
          visiting (first-party) or by third-party services. They do not collect personal
          information about you.
        </p>
        <p>We use performance cookies on our website.</p>
        <h2>Functionality cookies</h2>
        <p>
          Functionality cookies are used to collect information about your device and any settings
          you may configure on the website you’re visiting (like language and time zone settings).
          With this information, websites can provide you with customized, enhanced, or optimized
          content and services. These cookies may be set by the website you’re visiting
          (first-party) or by third-party services.
        </p>
        <p>We use functionality cookies for selected features on our website.</p>
        <h2>Targeting/advertising cookies</h2>
        <p>
          Targeting/advertising cookies help determine what promotional content is most relevant and
          appropriate to you and your interests. Websites may use them to deliver targeted
          advertising or limit the number of times you see an advertisement. This helps companies
          improve the effectiveness of their campaigns and the quality of content presented to you.
          These cookies may be set by the website you’re visiting (first-party) or by third-party
          services. Targeting/advertising cookies set by third-parties may be used to track you on
          other websites that use the same third-party service.
        </p>
        <p>We do not use this type of cookie on our website.</p>
      </>
    ),
  },
  {
    tab: 'Privacy Policy',
    body: (
      <>
        <h2>Privacy Policy</h2>
        <p>
          Your privacy is important to us. It is Datagrate Inc. (referred as Datagrate) policy to
          respect your privacy and comply with any applicable law and regulation regarding any
          personal information we may collect about you, including across our website,{' '}
          <a href="https://jetic.io" target="_blank" rel="noopener noreferrer">
            https://jetic.io
          </a>
          , and other sites and platforms (collectively, the “website”) we own and operate. This
          Privacy Policy describes the ways Datagrate collects, uses, and discloses information
          about you through Datagrate websites and services (the “Services”). In this Privacy Policy
          “you” refers to individuals who use Datagrate Services. By using the Services, you consent
          to the processing of your information described in this Privacy Policy.
        </p>
        <p>
          Personal information is any information about you which can be used to identify you. This
          includes information about you as a person (such as name, address, company name, and
          e-mail address), your devices, payment details, and information about how you use our
          website and/or service.
        </p>
        <p>
          This Privacy Policy does not apply to any of your activities after you leave our site.
        </p>
        <p>This policy is effective as of 5 August 2021.</p>
        <p>Last updated: 5 August 2021</p>
        <h2>Information We Collect</h2>
        <p>
          Information we collect falls into one of two categories: “voluntarily provided”
          information and “automatically collected” information.
        </p>
        <p>
          “Voluntarily provided” information refers to any information you knowingly and actively
          provide us when using{' '}
          <a href="https://jetic.io" target="_blank" rel="noopener noreferrer">
            https://jetic.io
          </a>{' '}
          website and/or any of our services or websites.
        </p>
        <p>
          “Automatically collected” information refers to any information automatically sent by your
          devices in the course of accessing our products and services.
        </p>
        <h2>Log Data</h2>
        <p>
          When you visit our website, our servers may automatically log the standard data provided
          by your web browser. It may include your device’s Internet Protocol (IP) address, your
          browser type and version, the pages you visit, the time and date of your visit, the time
          spent on each page, and other details about your visit.
        </p>
        <p>
          Additionally, if you encounter certain errors while using the site, we may automatically
          collect data about the error and the circumstances surrounding its occurrence. This data
          may include technical details about your device, what you were trying to do when the error
          happened, and other technical information relating to the problem. You may or may not
          receive notice of such errors, even in the moment they occur, that they have occurred, or
          what the nature of the error is.
        </p>
        <p>
          Please be aware that while this information may not be personally identifying by itself,
          it may be possible to combine it with other data to personally identify individual
          persons.
        </p>
        <h2>Personal Information</h2>
        <p>
          We may ask for personal information — for example, when you subscribe to our newsletter or
          when you contact us — which may include one or more of the following:
        </p>
        <ul>
          <li>Name</li>
          <li>Email</li>
          <li>Company Name</li>
        </ul>
        <h2>Transaction Data</h2>
        <p>
          Transaction data refers to data that accumulates over the normal course of operation on
          our platform. This may include transaction records, stored files, user profiles, analytics
          data and other metrics, as well as other types of information, created or generated, as
          users interact with our services.
        </p>
        <h2>Legitimate Reasons for Processing Your Personal Information</h2>
        <p>
          We only collect and use your personal information when we have a legitimate reason for
          doing so. In which instance, we only collect personal information that is reasonably
          necessary to provide our services to you.
        </p>
        <h2>Collection and Use of Information</h2>
        <p>
          We may collect personal information from you when you do any of the following on our
          website:
        </p>
        <ul>
          <li>Register for an account</li>
          <li>Purchase a subscription</li>
          <li>Sign up to receive updates from us via email or social media channels</li>
          <li>Use a mobile device or web browser to access our content</li>
          <li>Contact us via email, social media, or on any similar technologies</li>
          <li>When you mention us on social media</li>
        </ul>
        <p>
          We may collect, hold, use, and disclose information for the following purposes, and
          personal information will not be further processed in a manner that is incompatible with
          these purposes:
        </p>
        <ul>
          <li>to provide you with our platform's core features and services</li>
          <li>to enable you to customize or personalize your experience of our website</li>
          <li>to contact and communicate with you</li>
          <li>
            for analytics, market research, and business development, including to operate and
            improve our website, associated applications, and associated social media platforms
          </li>
          <li>
            to enable you to access and use our website, associated applications, and associated
            social media platforms
          </li>
          <li>for internal record keeping and administrative purposes</li>
          <li>for other purposes about which Datagrate notifies you</li>
        </ul>
        <p>
          We may combine voluntarily provided and automatically collected personal information with
          general information or research data we receive from other trusted sources. For example,
          If you provide us with your location, we may combine this with general information about
          currency and language to provide you with an enhanced experience of our site and service.
        </p>
        <h2>Security of Your Personal Information</h2>
        <p>
          When we collect and process personal information, and while we retain this information, we
          will protect it within commercially acceptable means to prevent loss and theft, as well as
          unauthorized access, disclosure, copying, use, or modification.
        </p>
        <p>
          Although we will do our best to protect the personal information you provide to us, we
          advise that no method of electronic transmission or storage is 100% secure, and no one can
          guarantee absolute data security.
        </p>
        <p>
          You are responsible for selecting any password and its overall security strength, ensuring
          the security of your own information within the bounds of our services. For example,
          ensuring any passwords associated with accessing your personal information and accounts
          are secure and confidential.
        </p>
        <h2>How Long We Keep Your Personal Information</h2>
        <p>
          We keep your personal information only for as long as we need to. This time period may
          depend on what we are using your information for, in accordance with this privacy policy.
          For example, if you have provided us with personal information as part of creating an
          account with us, we may retain this information for the duration your account exists on
          our system. If your personal information is no longer required for this purpose, we will
          delete it or make it anonymous by removing all details that identify you.
        </p>
        <p>
          However, if necessary, we may retain your personal information for our compliance with a
          legal, accounting, or reporting obligation or for archiving purposes in the public
          interest, scientific, or statistical purposes.
        </p>
        <p>
          You may update or change their registration information by logging in to your account at{' '}
          <a href="https://jetic.io" target="_blank" rel="noopener noreferrer">
            https://jetic.io
          </a>
          . Requests to access, change, or delete your information can be sent to{' '}
          <a href="mailto:support@jetic.io">support@jetic.io</a> and will be handled within 30 days.{' '}
        </p>
        <h2>Children’s Privacy</h2>
        <p>
          We do not aim any of our products or services directly at children under the age of 13,
          and we do not knowingly collect personal information about children under 13.
        </p>
        <h2>Disclosure of Personal Information to Third Parties</h2>
        <p>
          Datagrate does not share, sell, rent, or trade Personal Data with third parties for their
          promotional purposes.
        </p>
        <p>We may disclose personal information to:</p>
        <ul>
          <li>Datagrate subsidiary, or affiliate of our company</li>
          <li>
            third-party service providers for the purpose of enabling them to provide their
            services, including (without limitation) IT service providers, data storage, hosting and
            server providers, analytics, error loggers, debt collectors, maintenance or
            problem-solving providers, professional advisors, and payment systems operators
          </li>
          <li>our employees, contractors, and/or related entities</li>
          <li>our existing or potential agents or business partners</li>
          <li>
            credit reporting agencies, courts, tribunals, and regulatory authorities, in the event
            you fail to pay for goods or services we have provided to you
          </li>
          <li>
            courts, tribunals, regulatory authorities, and law enforcement officers, as required by
            law, in connection with any actual or prospective legal proceedings, or in order to
            establish, exercise, or defend our legal rights
          </li>
          <li>
            third parties, including agents or sub-contractors, who assist us in providing
            information, products, services, or direct marketing to you
          </li>
          <li>third parties to collect and process data</li>
          <li>
            an entity that buys, or to which we transfer all or substantially all of our assets and
            business
          </li>
        </ul>
        <p>Third parties we currently use include:</p>
        <ul>
          <li>Google Analytics</li>
          <li>Banking Institutions</li>
        </ul>
        <h2>International Transfers of Personal Information</h2>
        <p>
          The personal information we collect is stored and/or processed in United States, or where
          we or our partners, affiliates, and third-party providers maintain facilities.
        </p>
        <p>
          The countries to which we store, process, or transfer your personal information may not
          have the same data protection laws as the country in which you initially provided the
          information. If we transfer your personal information to third parties in other countries:
          (i) we will perform those transfers in accordance with the requirements of applicable law;
          and (ii) we will protect the transferred personal information in accordance with this
          privacy policy.
        </p>
        <h2>Your Rights and Controlling Your Personal Information</h2>
        <p>
          <b>Your choice:</b> By providing personal information to us, you understand we will
          collect, hold, use, and disclose your personal information in accordance with this privacy
          policy. You do not have to provide personal information to us, however, if you do not, it
          may affect your use of our website or the products and/or services offered on or through
          it.
        </p>
        <p>
          <b>Information from third parties:</b>If we receive personal information about you from a
          third party, we will protect it as set out in this privacy policy. If you are a third
          party providing personal information about somebody else, you represent and warrant that
          you have such person’s consent to provide the personal information to us.
        </p>
        <p>
          <b>Marketing permission:</b>If you have previously agreed to us using your personal
          information for direct marketing purposes, you may change your mind at any time by
          contacting us using the details below.
        </p>
        <p>
          <b>Access:</b>You may request details of the personal information that we hold about you.
        </p>
        <p>
          <b>Correction:</b>If you believe that any information we hold about you is inaccurate, out
          of date, incomplete, irrelevant, or misleading, please contact us using the details
          provided in this privacy policy. We will take reasonable steps to correct any information
          found to be inaccurate, incomplete, misleading, or out of date.
        </p>
        <p>
          <b>Non-discrimination:</b>We will not discriminate against you for exercising any of your
          rights over your personal information. Unless your personal information is required to
          provide you with a particular service or offer (for example processing transaction data),
          we will not deny you goods or services and/or charge you different prices or rates for
          goods or services, including through granting discounts or other benefits, or imposing
          penalties, or provide you with a different level or quality of goods or services.{' '}
        </p>
        <p>
          <b>Notification of data breaches:</b>We will comply with laws applicable to us in respect
          of any data breach.
        </p>
        <p>
          <b>Complaints:</b>If you believe that we have breached a relevant data protection law and
          wish to make a complaint, please contact us using the details below and provide us with
          full details of the alleged breach. We will promptly investigate your complaint and
          respond to you, in writing, setting out the outcome of our investigation and the steps we
          will take to deal with your complaint. You also have the right to contact a regulatory
          body or data protection authority in relation to your complaint.
        </p>
        <p>
          <b>Unsubscribe:</b>To unsubscribe from our email database or opt-out of communications
          (including marketing communications and newsletters), please contact us at{' '}
          <a href="mailto:support@jetic.io">support@jetic.io</a>. We may need to request specific
          information from you to help us confirm your identity.
        </p>
        <h2>Use of Cookies</h2>
        <p>
          We use “cookies” to collect information about you and your activity across our site. A
          cookie is a small piece of data that our website stores on your computer, and accesses
          each time you visit, so we can understand how you use our site. This helps us serve you
          content based on preferences you have specified.
        </p>
        <p>Please refer to our Cookie Policy for more information.</p>
        <h2>Business Transfers</h2>
        <p>
          If we or our assets are acquired, or in the unlikely event that we go out of business or
          enter bankruptcy, we would include data, including your personal information, among the
          assets transferred to any parties who acquire us. You acknowledge that such transfers may
          occur, and that any parties who acquire us may, to the extent permitted by applicable law,
          continue to use your personal information according to this policy, which they will be
          required to assume as it is the basis for any ownership or use rights we have over such
          information.
        </p>
        <h2>Limits of Our Policy</h2>
        <p>
          Our website may link to external sites that are not operated by us. Please be aware that
          we have no control over the content and policies of those sites, and cannot accept
          responsibility or liability for their respective privacy practices.
        </p>
        <h2>Changes to This Policy</h2>
        <p>
          At our discretion, we may change our privacy policy to reflect updates to our business
          processes, current acceptable practices, or legislative or regulatory changes. If we
          decide to change this privacy policy, we will post the changes here at the same link by
          which you are accessing this privacy policy.
        </p>
        <p>
          If the changes are significant, or if required by applicable law, we will contact you
          (based on your selected preferences for communications from us) and all our registered
          users with the new details and links to the updated or changed policy.
        </p>
        <p>
          If required by law, we will get your permission or give you the opportunity to opt in to
          or opt out of, as applicable, any new uses of your personal information.
        </p>
        <h2>
          Additional Disclosures for General Data Protection Regulation (GDPR) Compliance (EU)
        </h2>
        <h2>Data Controller / Data Processor</h2>
        <p>
          The GDPR distinguishes between organisations that process personal information for their
          own purposes (known as “data controllers”) and organizations that process personal
          information on behalf of other organizations (known as “data processors”). We, Datagrate,
          located at the address provided in our Contact Us section, are a Data Controller with
          respect to the personal information you provide to us.
        </p>
        <h2>Legal Bases for Processing Your Personal Information</h2>
        <p>
          We will only collect and use your personal information when we have a legal right to do
          so. In which case, we will collect and use your personal information lawfully, fairly, and
          in a transparent manner. If we seek your consent to process your personal information, and
          you are under 16 years of age, we will seek your parent or legal guardian’s consent to
          process your personal information for that specific purpose.
        </p>
        <p>
          Our lawful bases depend on the services you use and how you use them. This means we only
          collect and use your information on the following grounds:
        </p>
        <h2>Consent From You</h2>
        <p>
          Where you give us consent to collect and use your personal information for a specific
          purpose. You may withdraw your consent at any time using the facilities we provide;
          however this will not affect any use of your information that has already taken place. You
          may consent to providing your email address for the purpose of receiving marketing emails
          from us. While you may unsubscribe at any time, we cannot recall any email we have already
          sent. If you have any further inquiries about how to withdraw your consent, please feel
          free to enquire using the details provided in the Contact Us section of this privacy
          policy.
        </p>
        <h2>Performance of a Contract or Transaction</h2>
        <p>
          Where you have entered into a contract or transaction with us, or in order to take
          preparatory steps prior to our entering into a contract or transaction with you. For
          example, if you purchase a product, service, or subscription from us, we may need to use
          your personal and payment information in order to process and deliver your order.
        </p>
        <h2>Our Legitimate Interests</h2>
        <p>
          Where we assess it is necessary for our legitimate interests, such as for us to provide,
          operate, improve and communicate our services. We consider our legitimate interests to
          include research and development, understanding our audience, marketing and promoting our
          services, measures taken to operate our services efficiently, marketing analysis, and
          measures taken to protect our legal rights and interests.
        </p>
        <h2>Compliance with Law</h2>
        <p>
          In some cases, we may have a legal obligation to use or keep your personal information.
          Such cases may include (but are not limited to) court orders, criminal investigations,
          government requests, and regulatory obligations. If you have any further enquiries about
          how we retain personal information in order to comply with the law, please feel free to
          enquire using the details provided in the Contact Us section of this privacy policy.
        </p>
        <h2>International Transfers Outside of the European Economic Area (EEA)</h2>
        <p>
          We will ensure that any transfer of personal information from countries in the European
          Economic Area (EEA) to countries outside the EEA will be protected by appropriate
          safeguards, for example by using standard data protection clauses approved by the European
          Commission, or the use of binding corporate rules or other legally accepted means.
        </p>
        <h2>Your California Privacy Rights</h2>
        <p>
          Subject to certain limitations, the California Consumer Privacy Act (“CCPA”) provides
          California consumers the right to request to know more details about the categories or
          specific pieces of personal information Datagrate collects (including how Datagrate uses
          and discloses this information), to delete their personal information, to opt out of any
          “sales” that may be occurring, and to not be discriminated against for exercising these
          rights. If you are a resident of California (or another jurisdiction that provides
          comparable rights) and would like to make a request, please contact Datagrate at the
          address listed, below. Datagrate does not sell the personal information it collects to
          third parties for money or any other consideration, as that term is generally understood.
        </p>
        <h2>Your Rights and Controlling Your Personal Information</h2>
        <p>
          <b>Restrict:</b>You have the right to request that we restrict the processing of your
          personal information if (i) you are concerned about the accuracy of your personal
          information; (ii) you believe your personal information has been unlawfully processed;
          (iii) you need us to maintain the personal information solely for the purpose of a legal
          claim; or (iv) we are in the process of considering your objection in relation to
          processing on the basis of legitimate interests.
        </p>
        <p>
          <b>Objecting to processing:</b>You have the right to object to processing of your personal
          information that is based on our legitimate interests or public interest. If this is done,
          we must provide compelling legitimate grounds for the processing which overrides your
          interests, rights, and freedoms, in order to proceed with the processing of your personal
          information.
        </p>
        <p>
          <b>Data portability:</b>You may have the right to request a copy of the personal
          information we hold about you. Where possible, we will provide this information in CSV
          format or other easily readable machine format. You may also have the right to request
          that we transfer this personal information to a third party.
        </p>
        <p>
          <b>Deletion:</b>You may have a right to request that we delete the personal information we
          hold about you at any time, and we will take reasonable steps to delete your personal
          information from our current records. If you ask us to delete your personal information,
          we will let you know how the deletion affects your use of our website or products and
          services. There may be exceptions to this right for specific legal reasons which, if
          applicable, we will set out for you in response to your request. Please be aware that
          search engines and similar third parties may still retain copies of your personal
          information that has been made public at least once, like certain profile information and
          public comments, even after you have deleted the information from our services or
          deactivated your account.
        </p>
        <h2>Contact Us</h2>
        <p>
          For any questions or concerns regarding this privacy policy, please contact us by sending
          an email to <a href="mailto:support@jetic.io">support@jetic.io</a> or you can write to us
          at:
        </p>
        <br />
        <div>
          <span>Datagrate Inc. </span>
        </div>
        <div>
          <span>1658 N. Milwaukee Ave. Suite 100-2145</span>
        </div>
        <div>
          <span>Chicago, IL 60647</span>
        </div>
      </>
    ),
  },
];

const LegalPage = () => {
  const [activeTab, setActiveTab] = useState(tabsData[0].tab);

  const activeData = tabsData.find((item) => item.tab === activeTab);

  return (
    <Layout title="Legal Page">
      <div className="legal-page">
        <section className="first-section py-80 overflow-hidden">
          <div
            className="c-decoration"
            style={{ top: -220, left: -170, transform: 'rotate(90deg)' }}
          >
            <img src={DecorationLines} alt="decoration" />
          </div>
          <div
            className="c-decoration"
            style={{ top: -60, right: -205, transform: 'rotate(-90deg)' }}
          >
            <img src={DecorationLines} alt="decoration" />
          </div>

          <div className="container">
            <h1 className="header-700 mb-3 text-center">Legal</h1>
            <h2 className="body-300 gray-500 mb-4 text-center">
              Read Jetic’s comprehensive Terms of Service below
            </h2>
            <div className="tabs">
              <div className="tabs__header justify-content-center">
                {tabsData.map((item, index) => (
                  <div
                    className={classNames('tabs__tab body-300', {
                      'tabs__tab--active': activeTab === item.tab,
                    })}
                    key={index}
                    onClick={() => setActiveTab(item.tab)}
                  >
                    {item.tab}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="legal-page__content py-80">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">{activeData.body}</div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default LegalPage;
